import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { DetailsProject, Blockquote } from "../components/details.js";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import MoreProjects from "../components/moreProjects.js";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">{props.pageContext.frontmatter.shortTitle}</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <p>{`Six Degrees of Spotify is a website I created that demonstrates that the theory of `}<a href="https://en.wikipedia.org/wiki/Six_degrees_of_separation" className="link" target="_blank" rel="noreferrer">{`six degrees of separation`}</a>{` applies to the music world as well. This means that a musician is connected to almost every other musician through a chain of collaborations, typically with less than five artists between the two of them. From Paul McCartney to BLACKPINK to Ed Sheeran, this web of connections can be explored by searching for two artists, and a chain of connections with be displayed if there is a series of collaborations. I used data from `}<a href="https://developer.spotify.com/" className="link" target="_blank" rel="noreferrer">{`Spotify's API`}</a>{` which was gathered as of July 2020, and created this application using Java Spring Boot, the Vaadin UI framework, and a Neo4j graph database.`}</p>
    <DetailsProject role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="DetailsProject" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cde1e0a09b7a9d1989b7f2584bb78b0a/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7klEQVQ4y4WSy29UZRiHP+zczsyZznQu55w5M2cunXtpsaZ0ZWJiIFFMbW2d1tMb0gBSSwk1wURLYjUKaKAmThctMW5Y4ULjRlf+C5CoCUUjaoyBiaVcZGOkncfMN7SgGxZP8uXN+Z7v9573FT/fhi2u3YGr63C5VufHW/DLXbj2Pxq1n27D5Rt1Vtfht3vw618PEX39gzQYGKywZ+9zrHx2gesb8PHSMvv6+qm8MsbQsC1pnJ9/oZ/3T31IrQ6ff/UN/QMvMTo+iT02gT0+idDNJJJ4CqfHx5vzC9wBDhyaRgiBxxfA6VElihqUtRcHhrgHnF5cQogdhDUTl6LicHsRXd27yZe7iBgJWTj57mnWgenZN1DUNmKJDFqs+aiRSOP2Bhixx7kLnP1kGcNM8PQzz1Isd5ItlBG5zt2k8p0YVjstTg/zC6e49UDYGtJJZksk0nmsTIFCRzdaPM3I6ITs4mx1BcXnJ50tkMxkSbXnEZlcCSuVJRSN4XB5mV/4QAqPHD2Oy6MSNRKEozGCYQ3NsHA8oVAZHt1O6PMHyBU7SLfnHwizOfKFIrpp0eJUHkk4h9ffhhazJFEtTsSyCPSmGXl1simsLtMaDLGzq3tbKnK5MulMgYgep8WlPEw400zY+LchLSbR4kk8eojh0bFtodoapFDaSb7UQblzFyLeXcQotBOOmDgeEb7WECoqUdMirJuEdBPDTOFweKmMNFs+V13B6VZQA2Fs2+atE3OIeE8Jo5xF0y3cXj8n39ua8lxTaFhyLcJRU7bucPvklBtDObd0XibM5Io8+VQPPb29CKdLxeXx4w9G5I6dePudx+5h38Cg3MMzi1XEjhbiyQwRLUZbREccOjJDg8PTs0zsn+LCxS/5/W+oLn/Ky8M2kwcOMr5/StI4D1Vszny0yB//wBdff8vUwcPMHJvj9dnjEnFlrc4WqzfrfFfb4NKNTb6v3Wd1bYMrf/6X1bVNfqjd59L1Tfnt1ZvIe03gX6IPMilqdbVCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Six Degrees of Spotify header image",
            "title": "Six Degrees of Spotify header image",
            "src": "/static/cde1e0a09b7a9d1989b7f2584bb78b0a/1cfc2/header.png",
            "srcSet": ["/static/cde1e0a09b7a9d1989b7f2584bb78b0a/3684f/header.png 225w", "/static/cde1e0a09b7a9d1989b7f2584bb78b0a/fc2a6/header.png 450w", "/static/cde1e0a09b7a9d1989b7f2584bb78b0a/1cfc2/header.png 900w", "/static/cde1e0a09b7a9d1989b7f2584bb78b0a/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div id="quickstart"></div>
    <h4>{`Quickstart`}</h4>
    <h2>{`Running the project`}</h2>
    <ol>
      <li parentName="ol">{`Clone the `}<a href="https://github.com/emilyslouie/six-degrees" className="link" target="_blank" rel="noreferrer">{`repository`}</a></li>
      <li parentName="ol">{`Navigate to the project folder and run `}<code parentName="li" {...{
          "className": "language-bash"
        }}>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` docker-neo4j`}</code></li>
      <li parentName="ol">{`Run `}<code parentName="li" {...{
          "className": "language-bash"
        }}>{` docker-compose up -d`}</code>{` to start the Neo4j database`}</li>
      <li parentName="ol">{`Start the application by running `}<code parentName="li" {...{
          "className": "language-bash"
        }}>{` java -jar spotify-ui/target/spotify-ui-0.0.1-SNAPSHOT.jar`}</code></li>
      <li parentName="ol">{`Visit your localhost and search for two artist to try it out!`}</li>
      <li parentName="ol">{`When you are done, run `}<code parentName="li" {...{
          "className": "language-bash"
        }}>{` docker-compose down`}</code>{` to stop the Neo4j database and close your browser`}</li>
    </ol>
    <br />
    <AnchorLink to="/sixdegrees#solution" title="Solution" className="link" mdxType="AnchorLink">
  Jump to the final result.
    </AnchorLink>
    <h2>{`Why is it not live?`}</h2>
    <p>{`Unfortunately, the Neo4j database I am using requires too many resources to run properly using free services. I am currently looking for a solution and hope to find an easy way to run it on the web.`}</p>
    <h4>{`Context`}</h4>
    <h2>{`Inspiration`}</h2>
    <Blockquote text="The six degrees of separation is the theory that any person on the planet can be connected to any other person on the planet through a chain of acquaintances that has no more than five intermediaries." mdxType="Blockquote" />
    <a href="https://whatis.techtarget.com/definition/six-degrees-of-separation" className="link" target="_blank" rel="noreferrer">
  (Source)
    </a>
    <br />
    <br />
    <div className="row">
  <div className="column">
    <p>
      Listening to{" "}
      <a href="https://open.spotify.com/track/0Sayb1EWTywPttwJo7zjBt?si=P14dbX1aQYa5b4QtW3V9gA" className="link" target="_blank" rel="noreferrer">
        Six Degrees of Separation
      </a>{" "}
      by The Script (one of my favourite bands now) for the first time reminded
      me of the above theory of the six degrees of separation. While this theory
      has been disproven, I was interested in seeing if this theory would
      continue to be disproven in the music industry.
    </p>
    <p>
      Thus, I began to explore the use of the Spotify API which allows me to
      easily access thousands and thousands of artists and their songs. With
      this much data, I am able to draw those connections with some code and
      present it in an interesting way.
    </p>
  </div>
  <div className="column middle">
    <iframe src="https://open.spotify.com/embed/track/0Sayb1EWTywPttwJo7zjBt" width="300" height="350" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
  </div>
    </div>
    <p>{`A similar concept has also been done by Sunny Amrat's `}<a href="http://sixdegreesofkanyewest.com" className="link" target="_blank" rel="noreferrer">{`Six Degrees of Kanye West`}</a>{` `}<a href="https://github.com/sa2812/Six-Degrees-of-Kanye-West" className="link" target="_blank" rel="noreferrer">{`(Github)`}</a>{` which was built in Python and finds if an artist is within six or less collaboration songs with Kanye. I wanted to expand on this notion to create a more versatile application allowing the user to search between any two artists rather than just Kanye West and another. Thus, in terms of technicality, I knew this would be feasible since I could collect the artists from the API and map relationships between them to calculate the degrees of separation between two artists, this would just need to be a much larger database than the one Sunny used.`}</p>
    <h2>{`Goal`}</h2>
    <Blockquote text="How might we empower people to find the collaboration songs between their favourite artists?" mdxType="Blockquote" />
    <p>{`Originally, I thought it would interesting to show a data visualization that graphs the connections between artists based on collaboration songs in a web format. However, due to how large the visualization might be, I opted for narrowing the problem:`}</p>
    <Blockquote text="How might we help people find the path of collaboration songs between two artists?" mdxType="Blockquote" />
    <p>{`For me, this was a interesting problem to try and solve. As someone who listens to a lot of different music in different genres, I thought it would be interesting to see how the artists might be related, if at all. This also opens up some opportunities that I could explore in a future project, such as helping to create a seamless playlist between two artists even if they span across different genres.`}</p>
    <h4>{`Design and Development`}</h4>
    <h2>{`Creating the interface`}</h2>
    <p>{`I began with sketches of what I wanted the interface to look like. I know I wanted to make this mobile friendly, so the I opted for a more vertical layout to display the site. I also used a simple layout since I wanted the user interaction to focus on the searching of two artists to find the connections between them. Thus, I surfaced the search feature to the home page rather than the search being on a separate page. As such, I gave a brief introduction to what can be done using the website, and then two search fields with a call to action button to allow the user to immediately search.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/324dfb0672722b38eb3ec61f190b0dde/ad3bb/mockups.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABn0lEQVQoz5WRSY/TQBCF/f//AxIHxJULSBy5goSUZLKN0CjJMLbjJbGdtuPeP9TtzGiGE5T0VK+6q18tncwLz7qybCrHprIsC8ss16wKxfKomacji0yyKkyMAw8+xIEvspG7XLLMFbPckgSRuj5xPjec6ppdfubhpCnLYzy7jiNSSowxaK1RSkUf4nDX9z1N2yIuHb9qTbIsPU9pRp5lVMWRSigOHThr8N5jraO7CC5iQPTDjfeRG2MJ5rwHPPvWk8wLWK7vud9uydInnmoRBZ9NakPdDZy6a0TdTvyQFvxO89jtZJ5d40m2tUdrgxCCrm3Im+Em6GOatRatJFqr6I1Wt/cerAbvIn0RXJeW6txxbgVSaU7D1PqzoJSKc3OJIw/XMfK+vzJKw8c5bEpeCtw6dKi+xasB9EA3SPbtVDFUDnt0bkLkYa/OxTgUv2r/duTZ0fP+h+TDT82774YvG0smuIndwFvwyk+f8kpwUXi+bg2flyOfFopvD5ZU/C3xL3DsW0dyV3pKodkVDelJ8NhaVqXnsXUc/gMhf1V5/gD9uf8QbOj+WQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mockups of six degrees of spotify",
            "title": "mockups of six degrees of spotify",
            "src": "/static/324dfb0672722b38eb3ec61f190b0dde/1cfc2/mockups.png",
            "srcSet": ["/static/324dfb0672722b38eb3ec61f190b0dde/3684f/mockups.png 225w", "/static/324dfb0672722b38eb3ec61f190b0dde/fc2a6/mockups.png 450w", "/static/324dfb0672722b38eb3ec61f190b0dde/1cfc2/mockups.png 900w", "/static/324dfb0672722b38eb3ec61f190b0dde/21482/mockups.png 1350w", "/static/324dfb0672722b38eb3ec61f190b0dde/d61c2/mockups.png 1800w", "/static/324dfb0672722b38eb3ec61f190b0dde/ad3bb/mockups.png 2785w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  I designed some mockups in Figma to create direction for the frontend.
    </p>
    <p>{`With this layout, the user does not require very complex instructions as there are very few things going on, and thus a low cognitive load. It is also well-explained and intuitive to use.`}</p>
    <p>{`After the search is made, there are a few states the user can end up in:`}</p>
    <div className="list">
  <h3>
    1. Both artists are found in the database, and there is a path between them
  </h3>
  <p>
    If this first scenario occurs, the user will see a vertical layout
    displaying the name of the first artist on the collaboration song, followed
    by the Spotify embed of the collboration song, and the second artist
    underneath it. This pattern continues until the second artist from the
    initial search parameter is found.
  </p>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/32540b39616857c9479c50e8e22be92f/7da7d/mockups2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABm0lEQVQ4y5VSS47aQBT0NViwnOFeiKvkAllkyTXYIvHbMBpMJEeCMAmBsTFtutsBe2zofhV1N5qPM8znSaX3qXqlfrK9yUZjsY6wYhK/I45fYYK7e4YVSxHxDCE/YBkL/IkFwt3BzlZbaTVOmxiO7kKO20iR950BhyzDdhPhIc/wqSBld8qHjI6nE6YxkTeNCSdNjgegtYaUKWSagnMBIaXthZDYce5qKbHf793OefWoNPyXho5xhtIiz3MURYEkScAYQ1mWFkKIZ4Z0yfCRfAxjMpvN0Ov10O/34fs+wjB8upjoPUMnUkrZvt1uo9lsotFooF6vw/M8tFotyxnNBwzpuQhBECAIfqDb7aLT6WA4HGI+n7+mfePkM5xOQYqtNRoMBhiPx1gul58zNDAfxsR0MsC3r19wfX2FWq324mSjuWh4VP+fbCZFkYOxDUajkX2lyT8Xi3dfSNpNz/eiUhNJwUmK3SvcU620Jvtj+zFhnSpEfxXCKlKF+/SEtTxarERpc1Vndo3HJCZ4txvQTWRA51ztqYLLWuP1Dz8szbSWejhwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mockups of six degrees of spotify",
            "title": "mockups of six degrees of spotify",
            "src": "/static/32540b39616857c9479c50e8e22be92f/1cfc2/mockups2.png",
            "srcSet": ["/static/32540b39616857c9479c50e8e22be92f/3684f/mockups2.png 225w", "/static/32540b39616857c9479c50e8e22be92f/fc2a6/mockups2.png 450w", "/static/32540b39616857c9479c50e8e22be92f/1cfc2/mockups2.png 900w", "/static/32540b39616857c9479c50e8e22be92f/21482/mockups2.png 1350w", "/static/32540b39616857c9479c50e8e22be92f/d61c2/mockups2.png 1800w", "/static/32540b39616857c9479c50e8e22be92f/7da7d/mockups2.png 2120w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">Mockup of the first scenario.</p>
    <div className="list">
  <h3>
    2. Both artists are found in the database, and there is no path found
    between them
  </h3>
  <h3>3. One or both of the artists are not found in the database</h3>
  <p>
    If the second or third scenario occurs, I simplified the experience and made
    the content simply say "no connections found" to encompass both scenarios.
    This has some drawbacks since it does not explicitly indicate to the user
    why their specific search did not return any results, however, for an MVP,
    this suffices as a catch-all solution.
  </p>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a937a67072bd9667fffe4c7971e93ece/7da7d/mockups3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVQ4y52S6W6DMBCEef93q3L0d9OD1I4h9QG4UcHeqbyOGidR1BCk0Vo7O59hRbXcRbxsJerW4k1qvIo9Np8t6tZBag+hB7wrgw9lIL4G7tWN5Zk8u08ebYTGQgaqnltg8B7NTuLw7THrocCZn4OncZqwVkTVWhGmSNkHEGOEtQ7WORhjuWpjoLWGMQZd13G/7/ucOUbHELE6B2YnAy3LOQfvPYcTaBgGPifwCUi3gH/m6YuIOJjACeic5QtL/x/g2RDGcUTTNBBCQEoJqVrulZff/YZlIITAihfe3cDLWizh1sx9wHJP5Souz1fAMZSDmKlrIMXcPY5gpnImxEj8Y68UYesCZBcgHlTKJsZSEarFDvQkk+hYH1HOJtYvG6zw8Mw8SG4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mockups of six degrees of spotify",
            "title": "mockups of six degrees of spotify",
            "src": "/static/a937a67072bd9667fffe4c7971e93ece/1cfc2/mockups3.png",
            "srcSet": ["/static/a937a67072bd9667fffe4c7971e93ece/3684f/mockups3.png 225w", "/static/a937a67072bd9667fffe4c7971e93ece/fc2a6/mockups3.png 450w", "/static/a937a67072bd9667fffe4c7971e93ece/1cfc2/mockups3.png 900w", "/static/a937a67072bd9667fffe4c7971e93ece/21482/mockups3.png 1350w", "/static/a937a67072bd9667fffe4c7971e93ece/d61c2/mockups3.png 1800w", "/static/a937a67072bd9667fffe4c7971e93ece/7da7d/mockups3.png 2120w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  Mockup of the result from the second and third scenarios.
    </p>
    <h2>{`Exploring technologies`}</h2>
    <p>{`Starting off, I thought I could complete the query using only a breadth first search that would stop after 7 layers. While I knew this theoretically should work, practically, it was not feasible due to the amount of time it takes to request from the Spotify API and process the results. (Trust me, I tried...)`}</p>
    <p>{`Thus, I needed to pivot and create a more hefty application. I knew there was going to be 3 main parts of the application:`}</p>
    <ol>
      <li parentName="ol">{`Pinging the Spotify API and storing the artists and their songs in a database for easy searching`}</li>
      <li parentName="ol">{`Querying the artists requested by the user and finding the path of collaborations between them`}</li>
      <li parentName="ol">{`Creating the user interface and handling the user's interactions`}</li>
    </ol>
    <p>{`Because I wanted to further explore the use of Java and Spring, I used it as the base of my application. I have used Java for awhile and am familiar with it and Sping has many capabilities that make it easy to connect to databases and frontend frameworks.`}</p>
    <p>{`Initially, I used PostgreSQL to store each artist and their songs with collaborators. Theoretically, using an SQL query to join the table to find the correct path between two artists should be possible. However, practically, this proved once again to not be feasible due to the amount of time it takes (~18 minutes) to successfully locate the correct path.`}</p>
    <p>{`Instead, I chose to use a graph database, Neo4j, to store the artists and their songs. This made it easy to look up artists and find the path between them (less than a second)! Having not used a graph database before, it was a little difficult getting everything set up, but it was very cool to try out since Neo4j comes with a way to easily visualize the connections in the database.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/63b64732de06ffe676e06e95b8a3aeb0/b7244/neo4j.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.1111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACKUlEQVQ4y31V0ZLbIAzM/39dr9PpTXNfkDa5SWwDNkjaVosc+zJJHzRghJaVVuDD28nw448bHsb9+t7sicF8/HYyOxwvBjVDU4Rtc7H4FkET5Vzu61/2mqjh59ns8PFpANywjWaAKWTJ3eYJumRoGaG1wKQ9xngA3gl46YBmRpdvljmjpgG1TJCloJUJrSQ0X+e8m5n2GLMngHQo9A4wodW5g9YZLY8El7kDtzlByvgfQDOmx8A8QFrFkm5ovpau3bcD89RbuvGAJykD2ipZaZlQPdDnde4pL4U1rGWkj+v0L5B0g7aFIAQ8BmDNI1oZWT+y8DSdoae+rtGfoq4pDklekg3w45NKMEAilSWPIUgmIwqxprsUMvVS+L5+0OQ12wFqIxPZFX4VYqtbKOzs/HBpmKcrM1nS8AC4Y9iL7+IMaLUrLrUQrJJNgrQF6fqboNLjDCp4P2MDdHarGHIfM5kK2Q8UgXumK4Xxg9VJzMlsA4xbQUfuKXud5sy24ZqzDdFaMGV5oky6lJ0oobJ4CwSz3jKFAew5/w51A6C3jQv22DYroDf27G2y1rKEIHH9/NvbRKIkmse77/ldjqvHTRHEtvHUnL3fnvWgHOB5gNfOXj4O5mK1e9qyFj0OocJdhP44dLBnd3l7jLB7JBjsbIKZxtV0HzF2IV+vngOo9ueIYzBW5SPhpioE8nHvZ4yp+UjA7yfD8Sz49cKOZ6VxftFX+8zHt3+/gL8h3RVZt745MwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of Neo4j visualization",
            "title": "Example of Neo4j visualization",
            "src": "/static/63b64732de06ffe676e06e95b8a3aeb0/1cfc2/neo4j.png",
            "srcSet": ["/static/63b64732de06ffe676e06e95b8a3aeb0/3684f/neo4j.png 225w", "/static/63b64732de06ffe676e06e95b8a3aeb0/fc2a6/neo4j.png 450w", "/static/63b64732de06ffe676e06e95b8a3aeb0/1cfc2/neo4j.png 900w", "/static/63b64732de06ffe676e06e95b8a3aeb0/21482/neo4j.png 1350w", "/static/63b64732de06ffe676e06e95b8a3aeb0/d61c2/neo4j.png 1800w", "/static/63b64732de06ffe676e06e95b8a3aeb0/b7244/neo4j.png 3348w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  Example of a visualization of my Neo4j database. The orange nodes represent
  musicians that "play on" the songs shown in the brown nodes. Click the image
  to see the details, perhaps you can see your favourite artist!
    </p>
    <p>{`Also, Neo4j makes it really easy to query for the path between two artists as mentioned before. This can be done using the Cypher code snippet below and almost instantly returns a result:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "cypher"
    }}><pre parentName="div" {...{
        "className": "language-cypher"
      }}><code parentName="pre" {...{
          "className": "language-cypher"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`MATCH`}</span>{` p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`shortestPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`start`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Artist`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`spotifyId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$spotifyId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Artist`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`spotifyId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$spotifyId2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RETURN`}</span>{` p`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <br />
    <br />
After ensuring all of this worked in the console, I then began to store all of
the artists and their songs with collaborations by pinging the Spotify API.
    <p>{`Then, I developed the user interface using Vaadin, a Java UI framework. I had never used it before, but it was interesting to learn and made me appreciate how Vaadin makes it easy for backend developers to create frontend interfaces. It also made me appreciate frontend frameworks like React more due to the ease in customizability that it has for UIs over Vaadin.`}</p>
    <p>{`In the end, the complete architecture of the application resulted the following structure:`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/537252e3a7b511b8699aa59ad2246b6b/7da7d/architecture.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxklEQVQoz4WSa2/bIBSG8///VdVJ64dVXW9ZmjpN0sRNstmAL4Bt7PNMOEtvmzSkV6DDeR8OcCbzXPBBcAHc+7kTmgE6AR+OinutQOB9bPRIZMxzkclCCRB1Gm9roxWP8we0ymkbP8Z2LynT+zvKwjD04eSR6FsokUkSgSIMIsg4x4QBr/dsN8+cn39huVxSVRXeex4fE66vrynLkrq2pxIkepNX4IcR6G2Jr9xoqsqSoiho247GW0z2gvpVoJVBGGjblhDCCBmBCw3D0KO1oa4MWZpSFQ7fNKxXK+bzOdvtFmsdSmmm0ykPDwnr9YbDPsMYQ5Zl0veBheYEHEiShB/TKbvdAVsZynxHut2OQK01+/0OXxsaW2ArhbeGIvvJ8ukpHvhWYfLnU9I0ZTaboVROaSyFrri/u+Pi6wW3t7dsnjc0VY10LUPbQAiU+QGV5xwOB+n7/lhhBIoM44PH94hX7UKgrmuurr5zdnbGzc0Nq9Ua57rIoeuE0EHTBJxz1NbKeEt1bJtT33zQ0AepCjPK21q8q4/t8Jrzt29sm1kmKNej7EdpN1C0HNWAaf6d9yrXE1mTqz1yuZNPOsa+fdLlfxRZvwEhjUjDnT0GrAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Overall application architecture",
            "title": "Overall application architecture",
            "src": "/static/537252e3a7b511b8699aa59ad2246b6b/1cfc2/architecture.png",
            "srcSet": ["/static/537252e3a7b511b8699aa59ad2246b6b/3684f/architecture.png 225w", "/static/537252e3a7b511b8699aa59ad2246b6b/fc2a6/architecture.png 450w", "/static/537252e3a7b511b8699aa59ad2246b6b/1cfc2/architecture.png 900w", "/static/537252e3a7b511b8699aa59ad2246b6b/21482/architecture.png 1350w", "/static/537252e3a7b511b8699aa59ad2246b6b/d61c2/architecture.png 1800w", "/static/537252e3a7b511b8699aa59ad2246b6b/7da7d/architecture.png 2120w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The structure of the overall application at a high level.
    </p>
    <p>{`Overall, there are two parts to the application, the part involving user interaction and the batching application. The batching application calls the Spotify API and retrieves data which it adds to the Neo4j database. The user's journey begins in the browser where they will make a request that is processed by the main application. This is comprised of the UI that the user interacts with directly, the app's logic which processes the user's request, and the database level which take the requests and calls off to the Neo4j database which returns information that is surfaced back to the user's browser. The user's browser also calls Spotify content to display the embedded songs on the page.`}</p>
    <h2>{`Challenges`}</h2>
    <p>{`One of the challenges of using the Spotify API was not the API itself, but the mass amounts of data that the Spotify API can return. Originally, I thought it would be possible to clone most, if not all, of Spotify's information on songs with collaborators, however, I quickly realized how long this process was taking and the size of my database. I underestimated the amount of items on Spotify. Thus, I chose to focus on the more popular songs only include those since that would satisfy 80% of users.`}</p>
    <p>{`I also ran into the issue where I was using the wrong type of database to house my project. Initially, I thought a SQL database would make sense since there are queries that exist for the six-degrees-type query. However, upon trying it, performance-wise, it was unfeasible and not a good user experience. Thus, I switched to a graph database which helped make the query a lot easier and faster.`}</p>
    <p>{`In terms of the user interface, it did not feel cohesive and was generic. Thus, I chose to change the colour palette to match Spotify's colour scheme. This included a dark mode with green accents. This helped to make my application match and correlate better with Spotify.`}</p>
    <p>{`Lastly, I faced problems trying to host the application online for free. Needing to run my Neo4j database and serve the UI, there were not many options that had enough computing power to serve it onto the web. After trying several hosting platforms, I could not find a way to host the app for free.`}</p>
    <div id="solution"></div>
    <h4>{`Solution`}</h4>
    <h2>{`Final result`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6fe3410356ff39651bc57c12a415783f/b7244/final1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.111111111111114%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmElEQVQoz5WRO0vsQBiG05i9qclkMredZLPHXe8gtv4Ff5XFCoIWFno4oB6LXbXxAoIIFiqKoKidWIm9WPkLXskkWTegqMXL+83M+z3zMWNdPb2hc3yNzcNLrB+c57TWq/38uje3cXiBtYMznD68wNo+uYPUNRBfYJCwrlzKQagA6XpeWc4hDEQo2OUKFtd3EyBhCo7HjRNfGgV/RlEfnkTUmEC9OWHqWmPceDg0CjfNxaK8ikKxgsWNFEi5hhvf3AN0qTRNblZTCanriJpjCOrNbs4AhUah1I+lDOjxagI0zYkzGYDLIHEVwmMSHlPwXAEywJOsLz6f8DOg0lEKUpA6gtA1OA6HmhlDODud5Kj8ObCr7CPiMyLgj0TgU40UKL56w2oOFNfxnq80qErcU1UzMWMalOQvNsBSfwa8B5OhmdIX2nwQTWtfBvDlh7P0TeN1nEmkwVSIUsXB0v89WO3jGxTLA+izS7CLFdiFslHfN7J7FPdbloWFfzuwzh5fMf93C62VNlqrHbRW279Ux/TPLW/i6PYZ7w3Ec92y0YbBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Landing page",
            "title": "Landing page",
            "src": "/static/6fe3410356ff39651bc57c12a415783f/1cfc2/final1.png",
            "srcSet": ["/static/6fe3410356ff39651bc57c12a415783f/3684f/final1.png 225w", "/static/6fe3410356ff39651bc57c12a415783f/fc2a6/final1.png 450w", "/static/6fe3410356ff39651bc57c12a415783f/1cfc2/final1.png 900w", "/static/6fe3410356ff39651bc57c12a415783f/21482/final1.png 1350w", "/static/6fe3410356ff39651bc57c12a415783f/d61c2/final1.png 1800w", "/static/6fe3410356ff39651bc57c12a415783f/b7244/final1.png 3348w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">The landing page with search inputs.</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f08598db97aa02aa5b4d10460caa8db8/b7244/final2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+UlEQVQ4y6WTS2/TQBSFIyHaUFJcP2bsseNH/JDjpCGtgAUSW1Zs+otgwb6RYNsHFUtSoGHJgobkD6A2SIg1GyQkfsEcNGNs4lCgEYujsa1zv3vGM7c2/vQNLyczHL+fYTg+x/H4XK4vTs/+quFPn6yZzPhwfIbp5++89vjpESzHA3NDGFZTijLx3oLthrC9XI4Xym+FCHOll1hNmEHAFYPgyfM3vPZwdw9XrtahGhY2NIobKoFhOmjFbcRpF1GSwQ1iBFEqFSYZwqQNjTAoKoGiUVHLV1bXMHj2mtceDfZRX1uHbjoSKsGGJY0NRYdOGJK0KyHieX2jhJTSTYcLxuDoJAeuXmtAp3YO083SqFEbcdZH79ZddPq3YftxBVR4NWpzwbgQWEhsx3Ij3Lu/g2TzDqJsC17chUpYpemlgHlBXtRQDIRxG60olb9gMd0SwHzVDBNptomsexOW7f4GWxJIYVoMnu8jihN4fvB/QHGarBmg09tCp7cNk7nlCS+15XmwuLjM9sGcQHoW0y2VsEjp7GzDftCHohCo5NKH4lTTiVXPZfguDM+VkIsS6tT+88Uuuovx01kujTIpylypxa1XE+7uQ8yhnE2NlpJpjKrmG857VcL4Sv16PstvP3zB3qtTHI6mOBhNfunkH5rzHo6mXDDeffzKfwCFBAnfVv9bsgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Result after successful search",
            "title": "Result after successful search",
            "src": "/static/f08598db97aa02aa5b4d10460caa8db8/1cfc2/final2.png",
            "srcSet": ["/static/f08598db97aa02aa5b4d10460caa8db8/3684f/final2.png 225w", "/static/f08598db97aa02aa5b4d10460caa8db8/fc2a6/final2.png 450w", "/static/f08598db97aa02aa5b4d10460caa8db8/1cfc2/final2.png 900w", "/static/f08598db97aa02aa5b4d10460caa8db8/21482/final2.png 1350w", "/static/f08598db97aa02aa5b4d10460caa8db8/d61c2/final2.png 1800w", "/static/f08598db97aa02aa5b4d10460caa8db8/b7244/final2.png 3348w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The page with a successful search that returns a path between the two searched
  artists.
    </p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f3747706be61c71fcaee6c1c2329ca71/b7244/final3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.77777777777777%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABiklEQVQoz6WS3U7CMBzF9wDCNtZ2a9d9AyJEQBOvfBzfzg/inUQvMKIXxsQoBuKtBp/BxIse0+GMfGg0Xpyc0+X3P13aGmejF5zeTdG/fZ7Tye3Tt1pk+3fPSnfcTN+UUW+2EWUNeDIBD9JcIswg4xpkXP3QLPvRLIswnWO9LFPJegvHV4/KKFkEJcuBWaEo2xQl04HLA6TVBuK0jjCpIUrriJIagjhDVt8AdX3oOc3ruVLZUQ4TOLoYK6NCOWziofBCGuAyziXCBCJI4IfpEqfXluMqJiL0hhNl6I+W4+KrmxUGxgM0N7vobO2g091Bq72NVmc732gFrxgPlwsXS02bgXAfRAawXY41iyyV/Vg459SDZTLQdoZkbxekmcC2VnC//cPPzDiIL3P/jpsrXHUpOjMRoCIEKJewPZ6fqSvC/AUsspbDFBMfhXonfV6F57lYOzNfVMEVc2WbKOJJ9IZjZVBPgrg+Zv53US/QN6/0I+9dTpRxMBhhfzDCwT+0P7hXh+cPuH56Ve8+YZgypTCsAQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Result after unsuccessful search",
            "title": "Result after unsuccessful search",
            "src": "/static/f3747706be61c71fcaee6c1c2329ca71/1cfc2/final3.png",
            "srcSet": ["/static/f3747706be61c71fcaee6c1c2329ca71/3684f/final3.png 225w", "/static/f3747706be61c71fcaee6c1c2329ca71/fc2a6/final3.png 450w", "/static/f3747706be61c71fcaee6c1c2329ca71/1cfc2/final3.png 900w", "/static/f3747706be61c71fcaee6c1c2329ca71/21482/final3.png 1350w", "/static/f3747706be61c71fcaee6c1c2329ca71/d61c2/final3.png 1800w", "/static/f3747706be61c71fcaee6c1c2329ca71/b7244/final3.png 3348w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The page with an unsuccessful search that returns an error message.
    </p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b15fb51e9c177d059662d51811eae943/b7244/final4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxElEQVQoz5WSu0/bUBjFvZDYThwS++b6beMXDlECqVqEmLpU4k+pxEOCAQEZQFSQoSyItowVrUqrjki0ZUMgwQgdWenO33CqeyOH8Bhg+OlcS+cefdfnE44urrG0sY2FtS3MtjuYa3e4Ti9vYGZlk38/Rr93fvU93i6v4+fxXwifD04hFxVIchF5qdCjrBIogxUM5CXkRBm5e9rvLSiDEAQB6zt7EL4cnkOlJjTdAjFsEKa6hdEXr9BsvUQQpQjiLkPRMMKkBtsLoFGz56WWh7woo7O7D2Hv8AwVokOtGpzsbDo+HC+AF0Twgxi65YKaDlcW0n+natgYyInY3P12G5gZMg3jFHFaR5LW+WRdEq6OH94JJE8JjJIahkcaPMAdimDYfndqP4Bhuc8PZM/1wwTjE5NojrVQG6nDjUOotoWKbkBld54SmFGqEP5v2kuLmHrzGmONFK1mHYSaD7x3AlnLWtaybnFlsOlYo43RFrwkhhOGvBzWsGF7vZY13rJ72zLbQ1EucqSC0lVZgUYoqG5CJRRVaqBMq5BKJYhSsefPKJTKfA/fsT08ubrBx++/H/Bh/xfn/vkxPv34g+2vBzi6/If/WvRwRkPfqkEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "About page",
            "title": "About page",
            "src": "/static/b15fb51e9c177d059662d51811eae943/1cfc2/final4.png",
            "srcSet": ["/static/b15fb51e9c177d059662d51811eae943/3684f/final4.png 225w", "/static/b15fb51e9c177d059662d51811eae943/fc2a6/final4.png 450w", "/static/b15fb51e9c177d059662d51811eae943/1cfc2/final4.png 900w", "/static/b15fb51e9c177d059662d51811eae943/21482/final4.png 1350w", "/static/b15fb51e9c177d059662d51811eae943/d61c2/final4.png 1800w", "/static/b15fb51e9c177d059662d51811eae943/b7244/final4.png 3348w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The about page that describes my intentions for the site.
    </p>
    <p>{`The final application has the ability to search for two artists and view the path between them through song collaborations if such a path exists. The code for the site is available `}<a href="https://github.com/emilyslouie/six-degrees" className="link" target="_blank" rel="noreferrer">{`on my Github`}</a>{` to view. `}<AnchorLink to="/sixdegrees#quickstart" title="Quickstart" className="link" mdxType="AnchorLink">{`
See how to run the application.`}</AnchorLink></p>
    <h2>{`Future roadmap`}</h2>
    <div className="list">
  <h3>
    1. As a user, I want to try the application without cloning the git
    repository. (discoverability)
  </h3>
  <p>
    My first prioritization is hosting the project so that I can share the
    application and have others try it out! Currently, there is no feasible way
    to host the project for free, but I will continue looking.
  </p>
  <h3>
    2. New music comes out frequently, as a user, I want to see the latest
    collaboration songs appear when I search for certain connections. (staying
    current)
  </h3>
  <p>
    My next prioritization is to ensure the database of songs and collaborations
    stays up to date since my database is only current as of July 2020. This
    ensures that when someone searches up an artist with a new collaboration
    song, the song is recognized as a linker and may help to connect two artists
    if they were not connected before.
  </p>
  <p>
    One way to do this is to automate a process once-a-week to fetch new songs
    from the Spotify API via the weekly hot playlists which often feature new
    music. This way the database stays up to date with music that 80% of people
    will search for.
  </p>
  <h3>
    3. As a user, I want to create a playlist that has smooth transitions
    between two of my favourite artists using the collaboration songs between
    them. (new feature)
  </h3>
  <p>
    Similar to{" "}
    <a href="http://boilthefrog.playlistmachinery.com" className="link" target="_blank" rel="noreferrer">
      Boil the Frog
    </a>{" "}
    but using collaboration songs instead of artist similarity, this feature
    would allow the user to create a seamless playlist between two artists based
    on collaboration songs and other songs to help fill up the playlist. This
    would be relatively simple to do given the data I have collected and the
    fact that the Spotify API allows developers to create and save playlists to
    user accounts after authentication.
  </p>
  <h3>
    4. As a user, I want to see the path between the two artists I initially
    searched for, as well as see any other collaborators they may have. (new
    feature)
  </h3>
  <p>
    Similar to{" "}
    <a href="https://github.com/fsahin/artist-explorer" className="link" target="_blank" rel="noreferrer">
      Spotify Artist Explorer
    </a>{" "}
    but using collaboration songs instead, this feature would allow a user to
    search for two artists, show that initial connection through collaborations,
    and then also show any other collaborations with other artists in a web
    format. This could allow for the exploration of similar artists up to 2 or 3
    degrees rather than simply a linear path. To implement this, I would likely
    use a data visualization library like d3 to simplify the process.
  </p>
    </div>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      